<template>
  <div>
    <b-card title="Stoklarım">
      <b-row>
        <b-col cols="12"
          ><dx-data-grid
            :data-source="dataSource"
            :word-wrap-enabled="false"
            :remote-operations="false"
            column-resizing-mode="widget"
            :column-min-width="50"
            :show-borders="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            height="650px"
          >
            <DxEditing
              :allow-updating="false"
              :allow-adding="false"
              :allow-deleting="false"
              mode="cell"
            ></DxEditing>
            <DxFilterRow :visible="true" />
            <DxFilterPanel :visible="true" />
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition" />
            <DxHeaderFilter :visible="true" />

            <DxPaging :page-size="20" />

            <DxColumnFixing :enabled="true" />

            <DxColumnChooser :enabled="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxExport :enabled="true" :allow-export-selected-data="true" />

            <DxGroupPanel :visible="true" />
            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxPager
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="true"
            />
            <DxPaging :page-size="100" />
            <dx-column dataField="product.brand.name" caption="Marka" />
            <dx-column dataField="product.barcode" caption="Ürün Kodu" />
            <dx-column dataField="product.name" caption="Ürün Adı" />

            <dx-column
              dataField="unitPriceAverage"
              caption="Birim Ortalama Maliyeti (TL)"
              format="#,##0.###"
              data-type="number"
            />
            <dx-column
              dataField="product.sellPrice"
              caption="Birim Satış Fiyatı (TL)"
              format="#,##0.###"
              data-type="number"
            />
            <dx-column
              dataField="quantity"
              caption="Miktar"
              format="#,##0"
              data-type="number"
              sortOrder="asc"
            />

            <!-- <dx-column
              dataField="date"
              dataType="date"
              selectedFilterOperation=">="
              filterValue="2013/04/01"
              :width="150"
            /> -->
          </dx-data-grid></b-col
        >
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormSpinbutton,
} from "bootstrap-vue";
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxLoadPanel,
  DxMasterDetail,
  DxColumnFixing,
  DxStateStoring,
  DxHeaderFilter,
  DxFilterPanel,
  DxColumnChooser,
  DxPager,
  DxEditing,
  DxPopup,
  DxLookup,
  DxForm,
  DxPaging,
  DxSearchPanel,
  DxFilterBuilderPopup,
  DxScrolling,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { exportDataGrid } from "devextreme/excel_exporter";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    VBModal,
    BFormCheckbox,
    BFormSpinbutton,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxFilterRow,
    DxLoadPanel,
    DxMasterDetail,
    DxColumnFixing,
    DxStateStoring,
    DxHeaderFilter,
    DxFilterPanel,
    DxColumnChooser,
    DxPager,
    DxEditing,
    DxPopup,
    DxLookup,
    DxForm,
    DxPaging,
    DxSearchPanel,
    DxFilterBuilderPopup,
    DxScrolling,
    DxRequiredRule,
    DxEmailRule,
    DxPatternRule,
    DxAsyncRule,
    ToastificationContent,
  },
  data() {
    return {
      pageSizes: [50, 100, 200, 500],
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      show: false,
      dataSource: new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      }),
    };
  },
  computed: {},
  created() {
    this.getStock();
  },
  methods: {
    async getStock() {
      this.show = true;
      this.dataSource = new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      });
      this.$http
        .get("Stocks")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.dataSource = new DataSource({
              store: new ArrayStore({
                data: satirlar,
              }),
            });
            this.getBuys();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    // Ortalama Maliyet Hesabı
    async getBuys() {
      this.$http
        .get("buys")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var data = response.data;

            const groupedData = data.reduce((acc, curr) => {
              if (!acc[curr.productId]) {
                acc[curr.productId] = { totalQuantity: 0, totalPrice: 0 };
              }
              acc[curr.productId].totalQuantity += curr.quantity;
              acc[curr.productId].totalPrice += curr.unitPrice * curr.quantity;
              return acc;
            }, {});

            // Ortalamaları hesapla ve yeni bir dizi oluştur
            const averagesArray = Object.keys(groupedData).map((key) => ({
              productId: parseInt(key),
              unitPriceAverage:
                groupedData[key].totalPrice / groupedData[key].totalQuantity,
            }));

            var satirlar = this.dataSource.store()._array;

            for (var i = 0; i < satirlar.length; i++) {
              for (var a = 0; a < averagesArray.length; a++) {
                if (satirlar[i].productId == averagesArray[a].productId) {
                  satirlar[i].unitPriceAverage =
                    averagesArray[a].unitPriceAverage;
                }
              }
            }
            this.dataSource.reload();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style></style>
